<template>
  <div>
    <CCard>
      <CCardHeader>
         <CRow>
            <CCol md="6">
                <h4>My Dashboard</h4>
            </CCol>
          </CRow>
      </CCardHeader>

      <CCardBody>
        <div class="text-center">
            <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
        </div>
        <div v-html="userName"></div>
        <div v-html="groupRole"></div>

         <!-- <div>
          <b-table sticky-header :items="items" head-variant="light"></b-table>
        </div>  -->

      </CCardBody>

      <!-- <CCardFooter>
      </CCardFooter>        -->
    </CCard>

  </div>
</template>

<script>
import AdminService from '@/api/AdminService.js';

  export default {
    name: 'Dashboard',
    components: {
    },
    data () {
      return {
        spinner : false,
        userName: 'Welcome , <b>'+this.$store.getters.getUser+'</b>',
        groupRole:'',
        optGroup:[],
        // items: [
        //   { heading1: 'table cell', heading2: 'table cell', heading3: 'table cell' },
        //   { heading1: 'table cell', heading2: 'table cell', heading3: 'table cell' },
        //   { heading1: 'table cell', heading2: 'table cell', heading3: 'table cell' },
        //   { heading1: 'table cell', heading2: 'table cell', heading3: 'table cell' },
        //   { heading1: 'table cell', heading2: 'table cell', heading3: 'table cell' },
        //   { heading1: 'table cell', heading2: 'table cell', heading3: 'table cell' },
        //   { heading1: 'table cell', heading2: 'table cell', heading3: 'table cell' },
        //   { heading1: 'table cell', heading2: 'table cell', heading3: 'table cell' },
        //   { heading1: 'table cell', heading2: 'table cell', heading3: 'table cell' },
        //   { heading1: 'table cell', heading2: 'table cell', heading3: 'table cell' },
        //   { heading1: 'table cell', heading2: 'table cell', heading3: 'table cell' },
        //   { heading1: 'table cell', heading2: 'table cell', heading3: 'table cell' }
        // ]
      }
    },
    created(){
      this.getUserGroupList();
      setTimeout( () =>  this.showAllAdminList() , 2000);
    },
    methods: {
      showAllAdminList(){
          let params = {}; params['groupList'] = this.optGroup;
          var result = JSON.stringify(params);
          this.getUserAdminList(result);
      },

      getUserGroupList: function() {
          AdminService.getUserGroupList().then(resp => {
            this.spinner = true;
            var src = resp.payload; var init =[];
            for (var key in src) {
              var name = src[key].name;
              if(name!=="user") { init.push(name); }
            }
            this.optGroup = init;

           }, error => {
            console.log('error');
          });
      },

      getUserAdminList: function(params) {
        AdminService.getUserAdminList(params).then(resp => {
           var src = resp.payload;
           var logName = this.$store.getters.getUser.toLowerCase();
            for (var key in src) {
              var name = src[key].userName.toLowerCase();
              if(name === logName ) {
                var group =  src[key].groups[0].replace("_"," ").toUpperCase();
                this.groupRole = 'your roles group is <b>'+group+'</b>';
                this.spinner   = false;
              }
            }
        }, error => {
          this.spinner = false;
        });
      }

    }
  }
</script>